import React, { useState } from 'react';

const ItineraryDay = ({ day, date, activities }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white rounded-2xl p-4 sm:p-6 mb-4 sm:mb-6 lg:mb-8 shadow-lg transition-all duration-500 ease-in-out transform hover:shadow-2xl hover:-translate-y-1">
      <h2 
        className="text-indigo-700 cursor-pointer text-lg sm:text-xl font-bold py-4 px-6 flex justify-between items-center rounded-xl bg-gradient-to-r from-indigo-50 to-purple-50 transition-all duration-300 hover:from-indigo-100 hover:to-purple-100 hover:shadow-md"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="flex items-center space-x-2 sm:space-x-4">
          <span className="text-2xl sm:text-3xl text-indigo-500">Day {day}</span>
          <span className="text-xl sm:text-2xl text-gray-600">{date}</span>
        </span>
        <span className={`text-indigo-500 transition-transform duration-500 ease-in-out transform ${isOpen ? 'rotate-180' : ''}`}>
          ▼
        </span>
      </h2>
      <div className={`mt-4 space-y-4 transition-all duration-700 ease-in-out ${isOpen ? 'opacity-100 max-h-[5000px]' : 'opacity-0 max-h-0 overflow-hidden'}`}>
        {activities.map((activity, index) => (
          <div 
            key={index} 
            className="border-l-4 border-indigo-300 pl-4 py-3 transition-all duration-300 hover:border-indigo-500 hover:bg-indigo-50 rounded-r-xl hover:shadow-md transform hover:scale-105 active:translate-x-1"
          >
            <div className="flex items-center mb-2">
              <span className="text-2xl sm:text-3xl mr-4">{activity.icon}</span>
              <span className="font-bold text-indigo-600 text-lg sm:text-xl">{activity.time}</span>
            </div>
            <div className="text-gray-800 text-base sm:text-lg ml-12">{activity.description}</div>
            {activity.details && (
              <div className="mt-3 ml-12 space-y-2">
                {activity.details.map((detail, i) => (
                  <div key={i} className="text-sm sm:text-base text-gray-600 flex items-center">
                    <span className="w-3 h-3 rounded-full bg-indigo-100 mr-2 flex-shrink-0"></span>
                    {detail.includes('料金') ? (
                      <span className="text-red-500 font-semibold">{detail}</span>
                    ) : (
                      detail
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const BangkokItinerary = () => {
  const itinerary = [
    {
      day: 1,
      date: '8月7日',
      activities: [
        { icon: '✈️', time: '08:15', description: '福岡空港発 タイ・エアアジア FD237便' },
        { icon: '✈️', time: '11:40', description: 'バンコク・ドンムアン空港(DMK)到着' },
        { icon: '🏨', time: '12:00-13:00', description: '入国手続き、荷物受け取り' },
        { icon: '🚗', time: '13:00-14:00', description: 'バークレイ ホテル プラトゥナームへ移動、チェックイン', 
          details: [
            '距離：約25km、所要時間：45-60分',
            '💰 Grab料金：約400-500バーツ（1,600-2,000円）'
          ]
        },
        { icon: '🏨', time: '14:00-14:30', description: '休憩、身支度' },
        { icon: '🚶', time: '14:30-15:00', description: 'ホテルからゴーアン カオマンガイ プラトゥーナムへ徒歩移動',
          details: [
            '距離：約500m、所要時間：5-10分'
          ]
        },
        { icon: '🍽️', time: '15:00-16:00', description: 'ゴーアン カオマンガイ プラトゥーナムで遅めのランチ' },
        { icon: '🚗', time: '16:00-17:00', description: 'BTSとタクシーでワットパクナムへ移動',
          details: [
            '距離：約20km、所要時間：60分',
            '💰 Grab料金：約200-250バーツ（800-1,000円）'
          ]
        },
        { icon: '📷', time: '17:00-19:00', description: 'ワットパクナム見学（夕暮れ時の美しい景色を楽しむ）' },
        { icon: '🚗', time: '19:00-19:45', description: 'カオサン通りへ移動',
          details: [
            '距離：約20km、所要時間：45分',
            '💰 Grab料金：約200-250バーツ（800-1,000円）'
          ]
        },
        { icon: '🍽️', time: '19:45-22:00', description: 'カオサン通りで夕食、ナイトマーケット散策、バックパッカー文化体験' },
      ]
    },
    {
      day: 2,
      date: '8月8日',
      activities: [
        { icon: '🚗', time: '07:00-09:00', description: '早朝出発、アユタヤへ移動',
          details: [
            '距離：約80km、所要時間：1.5-2時間',
            '💰 Grab料金：約1,000-1,200バーツ（4,000-4,800円）'
          ]
        },
        { icon: '📷', time: '09:00-13:00', description: 'アユタヤ遺跡公園見学とゾウ保護区訪問',
          details: ['注意：ゾウ乗りではなく、エサやりや観察などの倫理的な体験を推奨']
        },
        { icon: '🍽️', time: '13:00-14:30', description: 'アユタヤでローカルランチ' },
        { icon: '🚗', time: '14:30-16:30', description: 'バンコク市内へ戻る',
          details: [
            '距離：約80km、所要時間：1.5-2時間',
            '💰 Grab料金：約1,000-1,200バーツ（4,000-4,800円）'
          ]
        },
        { icon: '🏨', time: '16:30-17:30', description: 'ホテルで休憩、シャワー' },
        { icon: '🚗', time: '17:30-18:30', description: 'クルアアロイアロイへ移動',
          details: [
            '距離：約7km、所要時間：30-40分',
            '💰 Grab料金：約100-150バーツ（400-600円）'
          ]
        },
        { icon: '🍽️', time: '18:30-20:00', description: 'クルアアロイアロイでマッサマンカレーのディナー' },
        { icon: '💆', time: '20:00-21:30', description: 'タイマッサージ体験 (近くのマッサージ店)' },
      ]
    },
    {
      day: 3,
      date: '8月9日',
      activities: [
        { icon: '🚗', time: '07:00-08:30', description: '早朝出発、ダムヌンサドゥアク水上マーケットへ移動',
          details: [
            '距離：約100km、所要時間：1.5-2時間',
            '💰 Grab料金：約1,200-1,500バーツ（4,800-6,000円）'
          ]
        },
        { icon: '📷', time: '08:30-10:30', description: '水上マーケット体験' },
        { icon: '🚗', time: '10:30-12:00', description: 'バブルインザフォレストカフェへ移動',
          details: [
            '距離：約40km、所要時間：1-1.5時間',
            '💰 Grab料金：約500-600バーツ（2,000-2,400円）'
          ]
        },
        { icon: '🍽️', time: '12:00-14:00', description: 'バブルインザフォレストカフェでランチ＆写真撮影' },
        { icon: '🚗', time: '14:00-15:00', description: 'ワットプラケオ（エメラルド寺院）＆王宮エリアへ移動',
          details: [
            '距離：約35km、所要時間：1時間',
            '💰 Grab料金：約400-500バーツ（1,600-2,000円）'
          ]
        },
        { icon: '📷', time: '15:00-17:30', description: 'ワットプラケオ＆王宮見学' },
        { icon: '🛍️', time: '17:30-19:00', description: 'サイアムエリアでショッピング、夕食',
          details: [
            '距離：約4km、所要時間：15分',
            '💰 BTS料金：約30-40バーツ（120-160円）'
          ]
        },
        { icon: '🏨', time: '19:00-20:30', description: 'ホテルに戻り、チェックアウト、荷物整理' },
        { icon: '🚗', time: '20:30-22:00', description: 'ドンムアン空港へ移動',
          details: [
            '距離：約25km、所要時間：45-60分',
            '💰 Grab料金：約400-500バーツ（1,600-2,000円）'
          ]
        },
        { icon: '✈️', time: '23:55', description: 'バンコク発 タイ・エアアジア FD236便 福岡行きフライト' },
      ]
    },
    {
      day: 4,
      date: '8月10日',
      activities: [
        { icon: '✈️', time: '07:05', description: '福岡空港着' },
      ]
    }
  ];

  return (
    <div className="font-sans leading-relaxed text-gray-800 max-w-full sm:max-w-3xl lg:max-w-5xl mx-auto p-6 sm:p-8 lg:p-10 bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 min-h-screen shadow-inner">
      <h1 className="text-center text-4xl sm:text-5xl lg:text-6xl mb-10 sm:mb-12 font-extrabold">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500">
          バンコク旅程表
        </span>
      </h1>
      <div className="space-y-6 sm:space-y-8 lg:space-y-10">
        {itinerary.map((day, index) => (
          <ItineraryDay key={index} {...day} />
        ))}
      </div>
    </div>
  );
};

export default BangkokItinerary;
