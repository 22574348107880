import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css'; // 必要に応じて他のCSSファイルをインポート
import BangkokItinerary from './BangkokItinerary'; // コンテンツが表示されない問題を解決

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>バンコク旅程表</title>
      </Helmet>
      {/* 他のコンポーネントやコード */}
      <BangkokItinerary /> {/* ここで主要なコンテンツを表示します */}
    </div>
  );
}

export default App;

